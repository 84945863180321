import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post('/user/login', formData);
      if (res.status === 200) {
        setUser(res.data.data.user);
        navigate('/');
      } else {
        throw new Error('Registration failed, please try again');
      }
    } catch (error) {
      Swal.fire({
        title: 'Registration Failed',
        text: error.message || 'Registration failed, please try again',
        icon: 'error',
        iconColor: '#004466',
        confirmButtonColor: '#004466'
      });
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="background-overlay"></div>
        <div className="login-container">
          <div className="form-wrapper">
            <h2 className="form-title">Login to Your Account</h2>
            <form className="login-form" onSubmit={handleSubmit}>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />

              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                required
              />

              <button type="submit" className="submit-button">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;