import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, DatePicker, Select, message } from 'antd';
import './MentorshipProgram.css';
import { useUser } from '../../contexts/userContext';
import API from "../../config/API";

const MentorshipProgram = () => {
  const { user } = useUser();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const res = await API.get('/user/getAll');
        setUsers(res.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchAllUsers();
    // eslint-disable-next-line
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFinish = async (values) => {
    try {
      const { mentor_id, mentee_id, start_date, end_date } = values;
      await API.post('/mentorship/create', {
        mentor_id,
        mentee_id,
        start_date: start_date.format('YYYY-MM-DD'),
        end_date: end_date.format('YYYY-MM-DD'),
      });
      message.success('Mentorship application submitted successfully!');
      form.resetFields();
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to submit application. Please try again later.');
    }
  };

  return (
    <>
      <div className="mentorship-container">
        <h1 className="mentorship-title">Join Our Mentorship Program</h1>

        {/* Existing content */}
        <div className="mentorship-intro">
          <p>
            Our Mentorship Program connects students and professionals with experienced mentors in various academic
            fields. Through personalized guidance, mentors help students develop their careers, overcome academic
            challenges, and achieve their personal and professional goals.
          </p>
          <p>
            Whether you’re looking for advice on research, publishing, career development, or work-life balance, our
            mentors are here to support you.
          </p>
        </div>

        <div className="mentorship-benefits">
          <h2>Program Benefits</h2>
          <ul>
            <li>Personalized guidance from experienced mentors</li>
            <li>Career development and networking opportunities</li>
            <li>Help with research and publishing strategies</li>
            <li>Access to workshops and academic resources</li>
            <li>Support in finding internships and job opportunities</li>
          </ul>
        </div>

        <div className="application-process">
          <h2>Application Process</h2>
          <ol>
            <li>Fill out the online application form with your academic and career interests.</li>
            <li>We will match you with a mentor based on your area of study and professional goals.</li>
            <li>Once matched, you'll receive an email with your mentor's contact information.</li>
            <li>Start your mentorship journey with a first meeting, either online or in person.</li>
          </ol>
          <p>
            <strong>Application Deadline:</strong> November 30, 2024
          </p>
        </div>

        {/* Call to Action */}
        <div className="cta-section">
          <p>Ready to grow and learn with our mentors? Apply now!</p>
          <Button type="primary" onClick={showModal} className="apply-button">
            Apply for Mentorship
          </Button>
        </div>
      </div>

      {/* Ant Design Modal */}
      <Modal
        title="Mentorship Application"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="mentor_id"
            label="Mentor"
            rules={[{ required: true, message: 'Please select a mentor' }]}
          >
            <Select placeholder="Select Mentor">
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name} {/* Adjust if user has a different name attribute */}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="mentee_id"
            label="Your ID"
            initialValue={user.id}
            rules={[{ required: true, message: 'Please enter your ID' }]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            name="start_date"
            label="Start Date"
            rules={[{ required: true, message: 'Please select the start date' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="end_date"
            label="End Date"
            rules={[{ required: true, message: 'Please select the end date' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit Application
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MentorshipProgram;