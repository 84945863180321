import React, { useState } from 'react';
import './AccountSettings.css';

const ChangePassword = () => {
  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate password fields
    if (password.newPassword !== password.confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    console.log("Password updated:", password);
  };

  return (
    <div className="password-section">
      <h3>Change Password</h3>
      <form onSubmit={handleSubmit}>
        <label>
          Current Password:
          <input
            type="password"
            name="currentPassword"
            value={password.currentPassword}
            onChange={handlePasswordChange}
            required
          />
        </label>
        <label>
          New Password:
          <input
            type="password"
            name="newPassword"
            value={password.newPassword}
            onChange={handlePasswordChange}
            required
          />
        </label>
        <label>
          Confirm Password:
          <input
            type="password"
            name="confirmPassword"
            value={password.confirmPassword}
            onChange={handlePasswordChange}
            required
          />
        </label>
        <button className="save-button" type="submit">Save Password Changes</button>
      </form>
    </div>
  );
};

export default ChangePassword;