import React, { createContext, useState, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';
import { backendUrl } from '../constants/backendURLs';
import { useUser } from './userContext';
import { toast } from 'react-toastify';
import API from '../config/API';

const messageContext = createContext();

export default function MessageProvider({ children }) {
  const [messages, setMessages] = useState([]);
  // const [socket, setSocket] = useState(null);
  const { user } = useUser();

  // Connect to the server with Socket.io
  useEffect(() => {
    const socketInstance = io(backendUrl);
    // setSocket(socketInstance);

    socketInstance.on('connect', () => {
      console.log('Connected to Socket.io server');
    });

    if (user) {
      socketInstance.on(`message:${user.id}`, (newMessage) => {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        toast.info(`New message`);
      });
    }

    return () => {
      socketInstance.disconnect();
    };
  }, [user]);

  const sendMessage = async (receiver_id, content) => {
    try {
      const response = await API.post('/message/send', { sender_id: user.id, receiver_id, content });
      setMessages((prevMessages) => [...prevMessages, response.data.data]);
      // socket.emit(`message:${receiver_id}`, response.data.data);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const fetchMessagesBetweenUsers = async (user1_id, user2_id) => {
    try {
      const response = await API.get(`/message/${user1_id}/${user2_id}`);
      setMessages(response.data.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchAllMessagesForUser = async (user_id) => {
    try {
      const response = await API.get(`/message/user/${user_id}`);
      setMessages(response.data.data);
    } catch (error) {
      console.error("Error fetching all messages for user:", error);
    }
  };

  return (
    <messageContext.Provider value={{ messages, sendMessage, fetchMessagesBetweenUsers, fetchAllMessagesForUser }}>
      {children}
    </messageContext.Provider>
  );
};

export const useMessage = () => useContext(messageContext);