import axios from 'axios';
import { serverUrl } from '../constants/backendURLs';

// Create an Axios instance
const API = axios.create({
  baseURL: serverUrl,
  withCredentials: true
});

// Set the Authorization header for every request
API.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.accessToken) {
      config.headers['Authorization'] = `Bearer ${user.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Redirect to login page on 401 Unauthorized response
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('user'); // Clear user data
      window.location.href = '/login'; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default API;