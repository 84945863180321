import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './AcademicEvents.css';
import API from '../../config/API';
import { Spin } from 'antd';

// Moment.js localizer for the calendar
const localizer = momentLocalizer(moment);

const AcademicEvents = () => {
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchEventsData = async () => {
      try {
        setIsLoading(true);
        const res = await API.get('/event/');
        // Transforming API data to match the expected format for the calendar
        const transformedEvents = res.data.map((event) => ({
          title: event.event_name, // Using event_name as the title
          start: new Date(event.event_date), // event_date as the start date
          end: new Date(event.event_date), // Same date for end if it's a one-day event
          location: event.event_description, // event_description as location
        }));
        setEventsData(transformedEvents);
      } catch (error) {
        console.error('Error fetching events data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEventsData();
  }, []);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen tip="Loading..." />
      <div className="events-container">
        <h1 className="events-title">Upcoming Academic Events</h1>

        {/* Calendar Section */}
        <div className="calendar-section">
          <Calendar
            localizer={localizer}
            events={eventsData}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            onSelectEvent={handleEventSelect}
          />
        </div>

        {/* Selected Event Details */}
        {selectedEvent && (
          <div className="event-details">
            <h2>{selectedEvent.title}</h2>
            <p><strong>Date:</strong> {moment(selectedEvent.start).format('MMMM Do YYYY')}</p>
            <p><strong>Location:</strong> {selectedEvent.location}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default AcademicEvents;