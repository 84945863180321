import React, { useEffect, useState } from 'react';
import { Layout, List, Typography, Avatar, Divider } from 'antd';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Chat.css';
import BackIcon from "../../icons/BackIcon";
import { useUser } from '../../contexts/userContext';
import Swal from 'sweetalert2';
import API from '../../config/API';

const { Sider, Content } = Layout;
const { Text } = Typography;

const ChatLayout = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const res = await API.get('/user/getAll');
        setUsers(res.data.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    fetchAllUsers();
    // eslint-disable-next-line
  }, []);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user } = useUser();
  useEffect(() => {
    if ((pathname !== '/login' && pathname !== '/registration') && !user) {
      Swal.fire({
        title: 'You need to login first',
        text: 'Please login to access this page',
        icon: 'warning',
        iconColor: '#004466',
        confirmButtonColor: '#004466'
      });
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [user, pathname]);

  return (
    <Layout style={{ height: '100vh', backgroundColor: '#f4f5f7' }}>
      <Sider width={300} style={{ backgroundColor: '#fff', padding: '20px', boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)' }}>
        <div className='flex items-center gap-2 mb-4'>
          <Link to="/">
            <BackIcon />
          </Link>
          <Text strong style={{ fontSize: '20px' }}>
            Active Users
          </Text>
        </div>
        <Divider style={{ margin: '0 0 20px 0' }} />
        <List
          dataSource={users}
          renderItem={(item) => (
            <List.Item>
              <Link to={`/chat/${item.id}`} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Avatar style={{ backgroundColor: '#1890ff', marginRight: '10px' }}>
                  {item.name[0]}
                </Avatar>
                <Text>{item.name}</Text>
              </Link>
            </List.Item>
          )}
        />
      </Sider>

      <Content style={{ padding: '20px', backgroundColor: '#fff', borderLeft: '1px solid #e8e8e8' }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default ChatLayout;