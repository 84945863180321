import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Button, Typography, List, Avatar, Divider } from 'antd';
import { useUser } from '../../contexts/userContext';
import { useMessage } from '../../contexts/messageContext';

const { Text } = Typography;

const Chat = () => {
  const { user: currentUser } = useUser();
  const { chatUserId } = useParams();
  const { messages, sendMessage, fetchMessagesBetweenUsers } = useMessage();
  const [messageContent, setMessageContent] = useState('');

  // Create a reference to the message container
  const messagesEndRef = useRef(null);

  useEffect(() => {
    fetchMessagesBetweenUsers(currentUser.id, chatUserId);
    // eslint-disable-next-line
  }, [currentUser.id, chatUserId]);

  // Scroll to the bottom when messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (messageContent.trim()) {
      sendMessage(chatUserId, messageContent);
      setMessageContent('');
    }
  };

  return (
    <div className="chat-content" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div className="messages-container" style={{ flex: 1, overflowY: 'auto', padding: '20px', backgroundColor: '#fafafa' }}>
        <List
          dataSource={messages}
          renderItem={(msg) => {
            const isCurrentUser = msg.sender_id === currentUser.id;
            return (
              <List.Item
                style={{
                  display: 'flex',
                  justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                  padding: '10px 0',
                }}
              >
                <div
                  style={{
                    maxWidth: '80%',
                    minWidth: '30%',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    backgroundColor: isCurrentUser ? '#2F4F4F' : '#f0f0f0',
                    color: isCurrentUser ? '#fff' : '#000',
                    textAlign: isCurrentUser ? 'right' : 'left',
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar style={{ backgroundColor: isCurrentUser ? '#1890ff' : '#f56a00', marginRight: isCurrentUser ? 0 : 10, marginLeft: isCurrentUser ? 10 : 0 }}>
                        {isCurrentUser ? 'You' : 'CU'}
                      </Avatar>
                    }
                    title={<span style={{ color: isCurrentUser ? '#fff' : '#555' }}>{isCurrentUser ? 'You' : 'Chat User'}</span>}
                    description={<span style={{ color: isCurrentUser ? '#e6f7ff' : '#333' }}>{msg.content}</span>}
                  />
                  <Text type="secondary" style={{ fontSize: '12px', color: isCurrentUser ? '#e6f7ff' : '#999' }}>
                    {new Date(msg.sent_at).toLocaleTimeString()}
                  </Text>
                </div>
              </List.Item>
            );
          }}
        />
        {/* Dummy div to help scroll to bottom */}
        <div ref={messagesEndRef} />
      </div>

      <Divider style={{ margin: '10px 0' }} />

      <div className="input-container" style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#fff' }}>
        <Input
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          placeholder="Type a message..."
          onPressEnter={handleSendMessage}
          style={{
            flex: 1,
            borderRadius: '25px',
            marginRight: '10px',
            padding: '12px 16px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
          }}
        />
        <Button
          type="primary"
          onClick={handleSendMessage}
          style={{
            backgroundColor: '#1890ff',
            borderRadius: '25px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default Chat;