import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/Home/Home';
import Registration from './Pages/Registration/Registration';
import ConnectPage from './Pages/ConnectPage/ConnectPage';
import CareerDevelopment from './Pages/CareerDevelopment/CareerDevelopment';
import JobOpportunities from './Pages/JobOpportunities/JobOpportunities';
import AcademicEvents from './Pages/AcademicEvents/AcademicEvents';
import MentorshipProgram from './Pages/MentorshipProgram/MentorshipProgram';
import AccountSettings from './Pages/AccountSettings/AccountSettings';
import ContactUs from './Pages/ContactUs/ContactUs';
import Profile from './Pages/Profile/Profile';


import './App.css';  // Global CSS
import Layout from './components/Layout';
import UserProvider from './contexts/userContext';
import Login from './Pages/Login/Login';

import axios from 'axios';
import { serverUrl } from "./constants/backendURLs";

import 'react-toastify/dist/ReactToastify.css';
import MessageProvider from './contexts/messageContext';
import ChatLayout from './Pages/Chat/ChatLayout';
import Chat from './Pages/Chat/Chat';
import { ToastContainer } from 'react-toastify';

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);

function App() {
  axios.defaults.baseURL = serverUrl;
  axios.defaults.withCredentials = true;

  return (
    <Router>
      <div className="App">
        <UserProvider>
          <MessageProvider>
            <ToastContainer />
            <Routes>
              <Route element={<Layout />} >
                <Route path="/" element={<HomePage />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/connect" element={<ConnectPage />} />
                <Route path="/career-development" element={<CareerDevelopment />} />
                <Route path="/job-opportunities" element={<JobOpportunities />} />
                <Route path="/academic-events" element={<AcademicEvents />} />
                <Route path="/mentorship-program" element={<MentorshipProgram />} />
                <Route path="/account-settings" element={<AccountSettings />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/profile" element={<Profile />} />
              </Route>
              <Route path="/chat" element={<ChatLayout />}>
                <Route path="/chat/:chatUserId" element={<Chat />} />
              </Route>
            </Routes>
          </MessageProvider>
        </UserProvider>
      </div>
    </Router>
  );
}

export default App;
