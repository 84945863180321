import React, { useState } from 'react';
import './Registration.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Registration = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post('/user/register', formData);
      if (res.status === 200) {
        console.log("Registration data: ", formData);
        Swal.fire({
          title: 'Registration Successfull',
          text: 'You have been registered successfully',
          icon: 'success',
          iconColor: '#004466',
          confirmButtonColor: '#004466'
        });
        navigate('/login');
      } else {
        throw new Error('Registration failed, please try again');
      }
    } catch (error) {
      Swal.fire({
        title: 'Registration Failed',
        text: error.message || 'Registration failed, please try again',
        icon: 'error',
        iconColor: '#004466',
        confirmButtonColor: '#004466'
      });
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="registration-page">
        <div className="background-overlay"></div>
        <div className="registration-container">
          <div className="form-wrapper">
            <h2 className="form-title">Create Your Account</h2>
            <form className="registration-form" onSubmit={handleSubmit}>
              <label>Username:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />

              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />

              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Create a password"
                required
              />

              <label>Confirm Password:</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm your password"
                required
              />

              <button type="submit" className="submit-button">
                Register
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
