import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { useUser } from '../../contexts/userContext';
import './Navbar.css';

const Navbar = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  // Menu for logged-out users
  const guestMenu = (
    <Menu>
      <Menu.Item key="login">
        <Link to="/login">Login</Link>
      </Menu.Item>
      <Menu.Item key="register">
        <Link to="/registration">Register</Link>
      </Menu.Item>
    </Menu>
  );

  // Menu for logged-in users
  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/profile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="chat">
        <Link to="/chat">Chat</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={() => {
        navigate('/login');
        setUser(null);
        localStorage.removeItem('user');
      }}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <h1><Link to="/">Campus<br />Connect</Link></h1>
      </div>

      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/connect">Connect</Link></li>
        <li><Link to="/career-development">Career Resources</Link></li>
        <li><Link to="/mentorship-program">Mentorship Program</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><Link to="/account-settings">Settings</Link></li>
      </ul>

      <div className="navbar-profile">
        {user ? (
          <Dropdown overlay={userMenu} placement="bottomRight">
            <Button type="primary">
              My Profile
            </Button>
          </Dropdown>
        ) : (
          <Dropdown overlay={guestMenu} placement="bottomRight">
            <Button type="primary">
              Login / Register
            </Button>
          </Dropdown>
        )}
      </div>
    </nav>
  );
};

export default Navbar;