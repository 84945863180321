import React, { useState } from 'react';
import './AccountSettings.css';
import { useUser } from '../../contexts/userContext'; // Assuming userContext provides the user's data
import ChangePassword from './ChangePassword'; // Assuming the ChangePassword form is separate

const AccountSettings = () => {
  const { user } = useUser();  // Assuming user data comes from context
  const [profile, setProfile] = useState({
    name: user.name || '',
    email: user.email || '',
    phone: user.phone || '',
    bio: user.bio || '',
    location: user.location || '',
  });

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Profile updated:", profile);
    // Add an API call or logic to save the updated profile.
  };

  const profileFields = [
    { label: 'Name', name: 'name', type: 'text' },
    { label: 'Email', name: 'email', type: 'email' },
    { label: 'Phone', name: 'phone', type: 'text' },
    { label: 'Bio', name: 'bio', type: 'text' },
    { label: 'Location', name: 'location', type: 'text' },
  ];

  return (
    <div className="account-settings-container">
      <h2 className="section-title">Manage Account Settings</h2>

      {/* Profile Info Section */}
      <div className="profile-info">
        {/* Profile Picture */}
        <div className="profile-picture">
          <img
            src={user.profilePicture || "https://media.istockphoto.com/id/1476170969/photo/portrait-of-young-man-ready-for-job-business-concept.jpg?s=1024x1024&w=is&k=20&c=8mgK2Kq73o8DIjazvLmEGkhx2p_7P5r3mvpbIM6q5cA="}
            alt="Profile"
          />
        </div>

        {/* Basic Info */}
        <div className="profile-details">
          <h1>{profile.name}</h1>
          <h2>Software Engineer | Full-Stack Developer</h2>
          <p>Location: {profile.location}</p>
          <p>Email: {profile.email}</p>
        </div>
      </div>

      {/* Profile Update Form */}
      <div className="profile-section">
        <h3>Update Profile Details</h3>
        <form onSubmit={handleSubmit}>
          {profileFields.map((field) => (
            <label key={field.name}>
              {field.label}:
              <input
                type={field.type}
                name={field.name}
                value={profile[field.name]}
                onChange={handleProfileChange}
                required={field.name !== 'bio' && field.name !== 'location'}
              />
            </label>
          ))}
          <button className="save-button" type="submit">Save Profile Changes</button>
        </form>
      </div>

      {/* Change Password Section */}
      <ChangePassword />
    </div>
  );
};

export default AccountSettings;