import React, { useState, useEffect } from 'react';
import { Card, Button, Select, Typography, Modal, Form, Input, message } from 'antd';
import './JobOpportunities.css';
import { useUser } from '../../contexts/userContext';
import API from '../../config/API';

const { Title, Text } = Typography;
const { Option } = Select;

const jobData = [
  { title: 'Software Engineer', company: 'TechCorp', location: 'New York, NY', type: 'Full-time', description: 'Develop software solutions...', link: '#' },
  { title: 'AI Research Intern', company: 'Innovate Labs', location: 'San Francisco, CA', type: 'Internship', description: 'Assist in AI research projects...', link: '#' },
  { title: 'Cybersecurity Analyst', company: 'SecureTech', location: 'Remote', type: 'Full-time', description: 'Protect systems from cyber threats...', link: '#' },
  { title: 'Marketing Intern', company: 'Creative Inc.', location: 'Chicago, IL', type: 'Internship', description: 'Support marketing campaigns...', link: '#' },
  { title: 'Frontend Developer', company: 'Web Solutions', location: 'Austin, TX', type: 'Full-time', description: 'Build user interfaces...', link: '#' },
];

const JobOpportunities = () => {
  const { user } = useUser();

  const [filter, setFilter] = useState('All');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [form] = Form.useForm();

  // Reset form and selected job whenever the modal is shown
  useEffect(() => {
    if (selectedJob) {
      form.setFieldsValue({
        jobTitle: selectedJob.title,
        jobDescription: selectedJob.description,
        email: '',
        poster_id: user.id,
        type: selectedJob.type,
        posted_at: new Date().toISOString(),
      });
    }
  }, [selectedJob, form, user.id]);

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleApplyClick = (job) => {
    setSelectedJob(job);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setSelectedJob(null);
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleFormSubmit = async (values) => {
    try {
      const postedAt = new Date().toISOString();
      let applicationData = {
        poster_id: user.id,
        posted_at: postedAt,
      };

      const type = selectedJob ? selectedJob.type : 'Unknown';  // Ensure selectedJob is not null

      if (type === 'Internship') {
        applicationData = {
          ...applicationData,
          internship_title: values.jobTitle,
          internship_description: values.jobDescription,
        };
      } else {
        applicationData = {
          ...applicationData,
          job_title: values.jobTitle,
          job_description: values.jobDescription,
        };
      }

      // Select the API endpoint based on the opportunity type
      let API_URL = '';
      if (type === 'Internship') {
        API_URL = '/internship/create';  // API endpoint for internships
      } else {
        API_URL = '/job/create';  // API endpoint for jobs
      }

      const res = await API.post(API_URL, applicationData);

      if (res.status === 201) {
        message.success('Application submitted successfully');
        handleModalCancel();  // Close the modal after successful submission
      } else {
        throw new Error('Failed to submit application');
      }
    } catch (error) {
      message.error('Error submitting application: ' + error.message);
    }
  };

  const filteredJobs = jobData.filter((job) => {
    if (filter === 'All') return true;
    return job.type === filter;
  });

  return (
    <div className="job-container bg-gradient-to-br from-gray-200 to-blue-200 p-8 min-h-screen">
      <Title level={2} className="text-blue-500 text-center mb-8">Job and Internship Opportunities</Title>

      {/* Job Filter Section */}
      <div className="filter-section flex justify-center items-center mb-8 gap-4">
        <Text>Filter by:</Text>
        <Select value={filter} onChange={handleFilterChange} style={{ width: 150 }}>
          <Option value="All">All</Option>
          <Option value="Full-time">Full-time</Option>
          <Option value="Internship">Internship</Option>
        </Select>
      </div>

      {/* Job Listing Section */}
      <div className="job-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-4">
        {filteredJobs.map((job, index) => (
          <Card
            key={index}
            className="job-card hover:scale-105 transition-transform duration-300"
            style={{
              backgroundColor: '#f0f8ff',
              borderColor: '#dfe6e9',
              borderRadius: '10px',
            }}
          >
            <Title level={4} className="text-blue-500 mb-2" style={{ whiteSpace: 'nowrap' }}>
              {job.title}
            </Title>
            <Text className="block text-gray-700 mb-1">{job.company}</Text>
            <Text className="block text-gray-500 mb-1">{job.location}</Text>
            <Text strong className="block text-gray-600 mb-4">{job.type}</Text>
            <Button
              type="primary"
              onClick={() => handleApplyClick(job)}
              className="apply-button bg-blue-500 hover:bg-red-500 text-white rounded-md"
            >
              Apply Now
            </Button>
          </Card>
        ))}
      </div>

      {/* Modal for submitting application */}
      <Modal
        title={`Apply for ${selectedJob ? selectedJob.title : ''}`}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        key={selectedJob?.title}  // Ensure the modal is recreated when selectedJob changes
      >
        <Form form={form} onFinish={handleFormSubmit}>
          {/* Job/Internship Title */}
          <Form.Item
            label="Job/Internship Title"
            name="jobTitle"
            initialValue={selectedJob?.title || ''}
            rules={[{ required: true, message: 'Please input the job or internship title!' }]}
          >
            <Input readOnly />
          </Form.Item>

          {/* Job/Internship Description */}
          <Form.Item
            label="Job/Internship Description"
            name="jobDescription"
            initialValue={selectedJob?.description || ''}
            rules={[{ required: true, message: 'Please input the description!' }]}
          >
            <Input.TextArea readOnly rows={4} />
          </Form.Item>

          {/* Email */}
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email!' }]}
          >
            <Input />
          </Form.Item>

          {/* Poster ID (Automatically set to the current user’s ID) */}
          <Form.Item name="poster_id" initialValue={user.id} hidden>
            <Input />
          </Form.Item>

          {/* Type */}
          <Form.Item name="type" initialValue={selectedJob?.type || ''} hidden>
            <Input />
          </Form.Item>

          {/* Date Posted */}
          <Form.Item name="posted_at" initialValue={new Date().toISOString()} hidden>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="bg-blue-500 hover:bg-red-500 text-white rounded-md">
              Submit Application
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default JobOpportunities;