import React, { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer/Footer";
import { useUser } from "../contexts/userContext";
import Swal from "sweetalert2";

export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    scrollToTop();
  }, [pathname]);

  const { user } = useUser();
  useEffect(() => {
    if ((pathname !== '/login' && pathname !== '/registration') && !user) {
      Swal.fire({
        title: 'You need to login first',
        text: 'Please login to access this page',
        icon: 'warning',
        iconColor: '#004466',
        confirmButtonColor: '#004466'
      });
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [user, pathname]);

  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
