import { createContext, useContext, useState, useEffect } from "react";

const userContext = createContext(null);

export default function UserProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Update localStorage whenever user changes
  const handleSetUser = (userData) => {
    setUser(userData);
    if (userData) {
      localStorage.setItem("user", JSON.stringify(userData));
    } else {
      localStorage.removeItem("user");
    }
  };

  return (
    <userContext.Provider value={{ user, setUser: handleSetUser }}>
      {children}
    </userContext.Provider>
  );
}

export const useUser = () => useContext(userContext);